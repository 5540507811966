/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
@import url('https://fonts.googleapis.com/css?family=Poppins');

:root {

  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-40: rgba(0, 0, 0, 0.4);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-70: rgba(0, 0, 0, 0.7);
  --black-alpha-80: rgba(0, 0, 0, 0.8);
  --gray-00: #F5F6F7;
  --gray-10: #EBEDF0;
  --gray-20: #DADDE1;
  --gray-30: #BEC3C9;
  --gray-40: #8D949E;
  --gray-70: #606770;
  --gray-80: #444950;
  --gray-90: #303338;
  --gray-100: #1C1E21;

  --primary: #30B7E7;
  --primary-alpha-80: rgba(48, 183, 231, 0.8);
  --primary-alpha-30: rgba(48, 183, 231, 0.3);
  --secondary: #FBC029;
  --secondary-alpha-80: rgba(251, 192, 41, 0.8);
  --green: #00bf6b;
  --red: #FC1E03;

}

.cta-back {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: fit-content;

}

.login_view {
  background-image: url('../public/assets/images/loginbg.png');
  background-size: cover;
  width: 100%;
  height: 100%;

}

.login_view_form {
  position: relative;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  /* top: 10%; */

}

.line-card {
  width: 300px;
  display: inline-block;
}

.container-fluid,
.row,
.row>* {
  padding: 0px;
  margin: 0px;
}

.container {
  padding: 0px;
}

.spinner-border {
  color: var(--green);
  border-width: 2px;
}

body,
html {
  margin: 0px;
  padding: 0px;
  background-color: #f3f3f3;

}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  font-family: 'Poppins';
}

body::-webkit-scrollbar {
  display: none;
}

.hide {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.shadow {
  -webkit-box-shadow: 0.05rem 0.05rem 0.20rem rgba(0, 0, 0, .15) !important;
  box-shadow: 0.05rem 0.05rem 0.20rem rgba(0, 0, 0, .15) !important;
}

.border {
  border: 1px solid red !important;
}

.border-0 {
  border: none !important;
}

.inf {
  height: 500px;
}

.inf::-webkit-scrollbar {
  display: none;
}

.bv_sidemenu {
  /* width: 90px; */
  width: 100px;
  height: 100%;
  background: white;
  /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(75,195,236,1)), color-stop(75%, rgba(0,143,197,1)));
  background: -o-linear-gradient(top, rgba(75,195,236,1) 0%, rgba(0,143,197,1) 75%);
  background: linear-gradient(180deg, rgba(75,195,236,1) 0%, rgba(0,143,197,1) 75%); */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.row-price {
  display: flex;
  flex-wrap: wrap;
}

.plan-container {
  /* border: 1px solid black; */
  max-width: 400px;
  padding: 5px;
  /* margin-bottom: 100px; */
}

.image-container {
  text-align: center;
}

.img-plan {
  max-width: 100px;
}

.bg-gray-card {
  background-color: #f3f3f3;
}

.suscription-button {
  background-color: #0074d4;
  border-radius: 5px;
  padding: 20px;
  width: 250px;
  text-align: center;
  color: white;
}

.sus-container {
  margin-bottom: 50px;
}

.sus-container {
  display: flex;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.height-name-dinner{
  height: 80px
}

.day-of-week{
  min-width: 115px;
}

.margin-bottom{
  margin-bottom: 100px
}

.margin-bottom-xxl{
  margin-bottom: 100px
}

.plan-description {
  height: 200px;
}

.plan-price {
  margin-bottom: 25px;
  display: flex;
}

.por-mes {
  max-width: 30px;
}

.mes {
  margin-bottom: -9px;
}

.render-html-content img{
 width: 100% !important;
}

.yt-video{
  height: 400px;
}

.bv_sidemenu_item {
  width: 100%;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-left: 3px;
}

.bv_sidemenu_item svg {
  color: #e7e7e7 !important;
}

.bv_sidemenu_item--active svg {
  color: #00bf6b !important;
}

.bv_sidemenu_item:hover>svg {
  color: #00bf6b !important;
}

.bv_sidemenu_item--active {
  border-left: 2px solid #00bf6b !important;
}

.bv_appmenu {
  z-index: 1;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 70px;
  /* background: rgb(75, 195, 236);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(75, 195, 236, 1)), color-stop(75%, rgba(0, 143, 197, 1)));
  background: -o-linear-gradient(top, rgba(75, 195, 236, 1) 0%, rgba(0, 143, 197, 1) 75%);
  background: linear-gradient(180deg, rgba(75, 195, 236, 1) 0%, rgba(0, 143, 197, 1) 75%); */
  background-color: #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bv_appmenu_item {
  width: 100%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-left: 3px;
}

.edit-btn {
  color: gold;
}

.btn-plan {
  background-color: aliceblue;

}

.bv_appmenu_submenu {
  z-index: 1;
  width: 40%;
  border-top-left-radius: 8px;
  bottom: 70px;
  right: 0px;
  position: fixed;
  -webkit-filter: drop-shadow(-3px -3px 3px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(-3px -3px 3px rgba(0, 0, 0, 0.3));

  /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 143, 197, 1)), color-stop(75%, rgba(75, 195, 236, 1)));

  background: -o-linear-gradient(top, rgba(0, 143, 197, 1) 0%, rgba(75, 195, 236, 1) 75%);

  background: linear-gradient(180deg, rgba(0, 143, 197, 1) 0%, rgba(75, 195, 236, 1) 75%); */
  background-color: #ebebeb;
}

.bv_appmenu_submenu .bv_appmenu_item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bv_appmenu_submenu .bv_appmenu_item div {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}



.bv-table {

  border: 1px solid #CCC;
  border-radius: 5px;
  /* min-width: 680px; */
  padding-bottom: 50px;


}

.b-light {
  border: 1px solid #CCC;
}

.bv-table thead {
  border-bottom: 1px solid #CCC;
}

.bv-table thead td {
  padding: 1rem;
}

.bv-table tbody td {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #CCC;
}


/* UTILERIAS */

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-26 {
  font-size: 24px !important;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-66 {
  font-size: 66px;
}

.line-0 {
  line-height: 1;
}

.line-0 {
  line-height: 1.2;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: bold;
}

.text-vertical {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.text-gray-bg {
  color: #f3f3f3;
}

.text-dark {
  color: var(--gray-80) !important;
}

.text-gray {
  color: #777777;
}

.text-gray-30 {
  color: #BEC3C9;
}

.text-blue {
  color: #0972CE;
}

.text-red {
  color: #FC1E03;
}

.text-maron {
  color: #c9154e;
}

.text-green {
  color: var(--green);
}

.text-orange {
  color: var(--secondary);
}

.text-sky {
  color: #FAFAFA;
}

.text-underline {
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

.w-85px {
  width: 85px;
}

.w-100px {
  width: 100px;
}


.w-150px {
  width: 150px;
}

.table-ingredients{
  margin-left: -30px;
  border-spacing: 30px 0px;
  border-collapse: separate;
}

.bg-white {
  background-color: #FAFAFA;
}

.bg-light {
  background-color: #FAFAFA;
}

.bg-lightblue {
  background-color: rgba(0, 0, 0, 0.01);
}

.bg-lightgreen {
  background-color: #D7F9EF;
}

.bg-lightpurple {
  background-color: #EEE5FF;
}

.bg-sky {
  background-color: #E3F8FF;
}

.bg-lightred {
  background-color: #FFE2E5;
}

.bg-gray-00 {
  background-color: var(--gray-00) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-green {
  background-color: var(--green) !important;
}

.bg-danger {
  background-color: rgba(233, 81, 30, 0.1) !important;
}

.bg-mint {
  background-color: rgba(55, 212, 173, 0.1) !important;
}

.bg-gradient-green {
  background: linear-gradient(70deg, rgb(4, 192, 109), rgb(138, 189, 36));
}

.bg-gradient-red {
  background: linear-gradient(70deg, rgb(221 21 21), rgb(225 107 51));
}

.bg-purple {
  background-color: #691FC8;
}

.bg-orange {
  background-color: #FAB020;
}

.bg-lightorange {
  background-color: rgba(255, 168, 0, 0.1) !important;
}

.border--gray {
  border: 1px solid var(--black-alpha-10);
}

.b-top {
  border-top: 1px solid var(--black-alpha-10);
}

.b-right {
  border-right: 1px solid var(--black-alpha-10);
}

.b-right-option {
  border-right: 2px solid white;
}

.b-bottom {
  border-bottom: 1px solid var(--black-alpha-10);
}

.cursor-pointer {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.cursor-auto {
  cursor: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.w-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.float-left {
  float: left;
}

#txtarea {
  resize: none;
  height: 150px;
}

#txtarea_response {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  /*remove the resize handle on the bottom right*/

}

.no-wrap {
  white-space: nowrap;
}

.select-curso {
  color: gray
}

.overlay {
  position: relative;
  padding: 1rem;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: red;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  overflow: none;
  z-index: 2001;
}



.max-plan-content {
  max-height: 250px;
  overflow: auto;
}

.highlight-active {
  background-color: #EEE
}

.highlight-text-hover:hover>div,
.highlight-text-hover:hover>div svg {
  color: #30B7E7 !important;
}

.highlight-hover:hover {
  background-color: var(--gray-00) !important;
}

.highlight-hover--light:hover {
  background-color: #fcfcfd;
}

.r-5 {
  border-radius: 5px !important;
}

.r-8 {
  border-radius: 8px;
}

.r-10 {
  border-radius: 10px;
}

.r-25 {
  border-radius: 25px;
}

.r-100 {
  border-radius: 100% !important;
}

.r-contents {

  width: 130%;
  left: calc(0% - 15%);
  border-top-right-radius: 100%;
  border-top-left-radius: 100%;
  padding-top: 50px;
  padding-bottom: 25px;
  padding-left: 15%;
  padding-right: 15%;
  position: relative;

}

.t-0 {
  top: 0;
}

.w-25-50-100 {
  width: 25% !important;
}

.w-50-100 {
  width: 100% !important;
}

.sticky {
  position: sticky;
  top: 0px;
}

.zindex-1 {
  z-index: 1;
}

.zindex-dropdown {
  z-index: 1000;

}

.text-primary {
  color: var(--primary) !important;
}

.text-dark {
  color: var(--gray-80)
}

.absolute-center-left {
  left: 0%;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.absolute-center {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.absolute-center--0 {
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.overlap-content>* {
  margin-left: -12px !important;
}

.text-ellipsis {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  height: 50px;
}

.text-ellipsis-punter{
  overflow: hidden;
  /* display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical; */
}

.absolute-top-right {
  position: absolute;
  right: 0rem;
  top: 0rem;
}

.no-wrap {
  white-space: nowrap;
}

/* .zindex-sticky{}                     1020
.zindex-fixed{}                      1030
.zindex-modal-backdrop{}             1040
.zindex-offcanvas{}                  1050
.zindex-modal{}                      1060
.zindex-popover{}                    1070
.zindex-tooltip{}                   1080 */

/* COMPONENTES */
.badge {
  position: absolute;
  background-color: red;
  border-radius: 100%;
  line-height: 1;
  width: 22px;
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0;
  font-size: 12.5px;
  font-weight: bold;
  overflow: hidden;
}

.badge--top-right {
  top: -6px;
  right: -6px;
}

.badge--top-left {
  top: -6px;
  left: -6px;
}

.badge--bottom-right {
  bottom: -6px;
  right: -6px;
}

.badge--bottom-left {
  bottom: -6px;
  left: -6px;
}

.days-container-week {
  max-height: 300px;
  overflow: auto;

}

.comments {
  max-height: 350px;
  overflow: auto;
}

.user-reponse {
  margin-top: 2px;
}

.days-container-week::-webkit-scrollbar,
.comments::-webkit-scrollbar {
  width: 15px;
  background-color: #F5F5F5;
}

.days-container-week::-webkit-scrollbar-track,
.comments::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.days-container-week::-webkit-scrollbar-thumb,
.comments::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--gray-20);
}

.border-left-gray {
  border-left: 1px solid #777777;
}

.border-bottom-gray {
  border-bottom: 1px solid #777777;
}

.border-top-gray {
  border-top: 1px solid #777777;
}

.bv-cta-mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 44px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--gray-20);
  border-radius: 6px;
  padding: 1rem 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--gray-100);
  font-weight: 600;
  font-size: 15px;
  line-height: 0.7;
  position: relative;
}

.bv-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 44px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--gray-20);
  border-radius: 6px;
  padding: 1rem 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--gray-100);
  font-weight: 600;
  font-size: 15px;
  line-height: 0.7;
  position: relative;
}

.bv-cta:hover {
  background-color: var(--gray-10)
}

.bv-cta--unselect:hover {
  background-color: var(--gray-40)
}

.bv-cta-small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--gray-20);
  border-radius: 6px;
  padding: 1rem 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: green;
  font-weight: 600;
  font-size: 15px;
  line-height: 0.7;
  position: relative;
}

.bv-cta-small:hover {
  background-color: var(--gray-10)
}

.bv-cta--success-mobile {
  background-image: linear-gradient(74.6237deg, rgb(44, 173, 110), rgb(138, 189, 36));
  color: white;
}

.bv-cta--success {
  background-image: linear-gradient(74.6237deg, rgb(44, 173, 110), rgb(138, 189, 36));
  color: white;
}

.bv-cta--unselect {
  background-color: var(--gray-40);
  color: white;
}

.bv-cta--danger {
  background-image: linear-gradient(74.6237deg, rgb(198, 2, 62), rgb(227, 84, 12));
  color: white;
}

.bv-cta--disabled {
  background-image: linear-gradient(74.6237deg, rgb(213, 213, 213), rgb(209, 209, 209));
  color: white;
  cursor: not-allowed;
}









.bv-cta--primary {
  background-color: var(--primary);
  color: white;
}

.bv-cta--primary:hover {
  background-color: var(--primary-alpha-80);

}

.bv-cta--secondary {
  background-color: var(--secondary);
  color: white;
}

.bv-cta--secondary:hover {
  background-color: var(--secondary-alpha-80);
}

.bv-cta--outline {
  border: 1px solid var(--gray-30);
  background-color: transparent;
  padding: 0.5rem 0.5rem;
}

.bv-cta--outline:hover {
  background-color: transparent;
}


.bv-dropdown {


  display: -webkit-box;


  display: -ms-flexbox;


  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--black-alpha-30);
  border-radius: 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  min-height: 44px;
  min-width: 44px;
  padding: 0.25rem 0.5rem;


}

.bv-dropdown--primary {
  background-color: #30B7E7 !important;
  border: none;
}

.bv-list-option {
  bottom: 0px;
  position: absolute;
  background-color: #FFF;
  z-index: 2002;
  -webkit-box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .1) !important;
  box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .1) !important;
  border: 1px solid #CCC;
  border-radius: 5px;
  width: 100%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  max-height: 200px;
  overflow: auto;
}

.bv-dropdown-option {
  position: absolute;
  background-color: #FFF;
  z-index: 1000;
  -webkit-box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .1) !important;
  box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .1) !important;
  border: 1px solid #CCC;
  border-radius: 5px;
  width: 100%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  max-height: 300px;
  overflow: auto;
}

.bv-dropdown-option--bottom-left {
  top: calc(100% + 5px);
  right: 0px
}

.bv-dropdown-option--bottom-right {
  top: calc(100% + 5px);
  left: 0px
}

.bv-dropdown-option--top-left {
  bottom: calc(100% + 5px);
  right: 0px
}

.bv-dropdown-option--top-right {
  bottom: calc(100% + 5px);
  left: 0px
}

.bv-dropdown-title {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  font-weight: bold;
}

.bv-dropdown--primary .bv-dropdown-title {
  color: white !important;
  font-size: 12px;
}

.bv-dropdown--light .bv-dropdown-title {
  color: #777777 !important;
  font-size: 12px;
}

.bv-pill {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 30px;
  padding: 0.25rem 1rem;
  white-space: pre;
  font-weight: bolder;
  font-size: 12px;
  min-width: 80px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-box-shadow: 0 .125rem 0.25rem rgba(0, 0, 0, .15) !important;
  box-shadow: 0 .125rem 0.25rem rgba(0, 0, 0, .15) !important;

}

.bv-pill--secondary {
  background-color: var(--secondary);
  color: white;

}

.bv-pill--primary {
  background-color: #30B7E7;
  color: white;

}

.bv-pill--light {
  background-color: #E1E0E0;
  color: white;

}

.txt-white {
  color: white;
}

.bv-pill--light-txt-black {
  background-color: #E1E0E0;
  color: black;

}

.bv-pill--green {
  background-color: #37D4AD;
  color: white;
}

.bv-pill--red {
  background-color: var(--red);
  color: white;
}

.bv-pill--darkblue {
  background-color: #0972CE;
  color: white;
}

.bv-pill--gray {
  background-color: #979797;
  color: white;

}

.bv-tag--gray {
  background-color: var(--gray-10) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bv-tag {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  padding: 0.25rem 0.25rem;
  white-space: pre;
  font-weight: bolder;
  font-size: 12px;
  background-color: #000;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-box-shadow: 0 .125rem 0.25rem rgba(0, 0, 0, .15);
  box-shadow: 0 .125rem 0.25rem rgba(0, 0, 0, .15);
  max-width: 250px;
}

.bv-tag div:nth-child(1) {

  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bv-tab {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  padding: 0.5rem 0.5rem;
  font-size: 14px;
  font-weight: 500;
  color: #b4b4b4;
  border: 1px solid #CCC;
  margin-right: 0.25rem;
  white-space: pre;
  line-height: 1;

}

.bv-tab:hover {
  background-color: rgba(0, 0, 0, 0.01);
}

.bv-tab--primary {
  background-color: transparent;
  border: 1px solid #CCC;
}

.bv-tab--primary:hover {
  background-color: #C0EFFF;
  border: 1px solid #00bf6b;
}

.bv-tab--active {
  /* background-color: white; */
  border: 1px solid #00bf6b;
  color: #00bf6b;
}

.avatar {

  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-image: url('https://source.boringavatars.com/beam/120/Stefan?colors=ffffff,00bf6b');
  background-position: center;
  background-size: cover;
  border: 1px solid white;
  outline: 2px solid transparent;
  outline-offset: 0px;
  margin: 2px;
}

.avatar--active {

  border: 1px solid white;
  outline: 2px solid var(--primary);
  outline-offset: 0px;
}

.avatar--mid {
  width: 48px;
  height: 48px;
}

.avatar--big {
  width: 64px;
  height: 64px;
}


.avatar--xl {
  width: 84px;
  height: 84px;
}

.avatar--xxl {
  width: 150px;
  height: 150px;
}

.bv-calendar {
  background-color: white;
  width: 100%;
  border-radius: 8px;
  padding: 0px !important;
  overflow: hidden;
}

.bv-calendar-day {
  color: #777;
  border: none;
  background-color: transparent;
  padding: 10px 5px;
  border-radius: 3px;
  border: 2px solid transparent;

}

.bv-calendar-day:hover {
  border: 2px solid #00bf6b;
  

}

.react-calendar__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;


  padding-top: 10px;
  padding-bottom: 15px;
}

.react-calendar .react-calendar__navigation button {
  background-color: transparent;
  border: none;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.react-calendar__navigation__arrow {
  color: #FFF;
  font-size: 20px;
}

.react-calendar__tile--active {
  /* border: 2px solid #00bf6b; */
  position: relative;
 
  /* border-radius: 40px; */
}


.react-calendar__navigation__label__labelText {
  color: #FFF;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 22px;
}

/* .react-calendar__tile--now{
  border: 2px solid #30B7E7;
} */
.react-calendar__tile--now {
  position: relative;
}

.react-calendar__tile--active::after,
.react-calendar__tile--now::before {
  content: '•';
  color: var(--green);
  font-size: 30px;
  position: absolute;
  top: 72%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.react-calendar__tile--active::before{
  content: '';
  color: var(--green);
  background-color: rgba(0, 0, 0, .08);
  font-size: 30px;
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 35px;
  top:50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.react-calendar__month-view__weekdays {
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none;
  color: #777;

}

.react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none;
  color: var(--green);
  font-weight: bold;
}

.bv-calendar .react-calendar__navigation{
  background-image: linear-gradient(74.6237deg, rgb(44, 173, 110), rgb(138, 189, 36));
}

.ws-icon {
  position: absolute !important;
  z-index: 10001;
  /* bottom: 30px; */
  top: 10px;
  right: 30px;


}

.bv-icon {
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  outline: 2px solid transparent;
  margin: 2px;
}

.bv-icon-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  outline: 2px solid transparent;
  margin: 2px;
}
.bv-icon--md {
  width: 32px;
  height: 32px;
}
.bv-icon--mid {
  width: 26px;
  height: 26px;
}

.bv-icon--big {
  width: 46px;
  height: 46px;
}

.bv-icon--xl {
  width: 84px;
  height: 84px;
}

.bv-slider {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  background-color: var(--primary-alpha-30);
  overflow: auto;
  border-radius: 12px;
}

.bv-slider-image {

  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bv-slider-info {
  padding: 1rem 1.5rem;
}

.bv-slider-dot {
  margin: 0px 4px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: gray;
}

.bv-slider-dot--active {
  background-color: #30B7E7;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: var(--black-alpha-20);
}

.dot-text {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: var(--black-alpha-20);
}

.dot--primary {
  background-color: var(--primary);
}

.dot--green {
  background-color: var(--green);
}

.list-style-none {
  list-style: none;
}

.bv-list-select {
  background-color: white;
  -webkit-box-shadow: 1px 1px 4px 0px #CCC;
  box-shadow: 1px 1px 4px 0px #CCC;
  border-radius: 5px;
  position: fixed;


  max-height: 264px;
  overflow: auto;

}

.bv-list-select-item {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.5rem;
  padding-right: 50px;
  height: 44px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bv-list-select-item:hover {
  background-color: var(--gray-00) !important;
}

.bv-card {

  background-color: white;
  -webkit-box-shadow: 1px 0px 3px 0px #CCC;
  box-shadow: 1px 0px 3px 0px #CCC;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;

}

.bv-card-img {
  width: 100%;
  height: 90px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bv-card-img--small {
  height: 70px;
}

.bv-card-img--full {
  height: 300px;
}

.bv-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 50%;
  max-height: 90vh;
  background-color: white;
  border-radius: 9px;
  overflow-y: auto;
  /* overflow: hidden; */

}

.bv-modal--card {
  width: 450px
}

.bv-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 2002;
}

.bv-quiz {
  width: 100%;
  position: fixed;
  background-color: red;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

}

.bv-quiz .bv-modal {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0%;
  width: 460px;
  height: 75vh;
  max-height: 75vh;
}

.bv-quiz .bars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;

  padding-left: 25px;
  padding-right: 25px;
}

.bv-quiz .bars .bar {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 6px;
  background-color: red;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: var(--black-alpha-10);
}

.bv-quiz .bars .bar--active {
  background-color: var(--black-alpha-70);
}

.input--light {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 0px 10px;
  outline-color: #30B7E7;
  font-size: 14px;
  color: #777;
  min-height: 44px;
  width: 100%;
}

.input--light input {
  font-size: 15px;
  width: 100%;
  min-width: 180px;
  border: none;
  outline: none;
  line-height: 1;
  padding: 0px 0.5rem;
  color: var(--gray-70);
}

.bv-login-bg {
  width: 100%;
  height: 100%;
  background-image: url('https://picsum.photos/800/800');
  background-position: center center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}

.vh-85 {
  height: 85vh;
}

.sticky-top-header {
  position: sticky !important;
  top: calc(0px) !important;
  z-index: 1020 !important;
}

.back-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.bv-login-bg img {

  width: 60%;

  border: 12px solid #FFF;
  border-radius: 5px;
  -o-object-fit: contain;
  object-fit: contain;


}

.bv-login-form {
  width: 50%
}

.bv-alert-notification-holder {
  pointer-events: none;
  position: fixed;
  overflow: hidden;
  right: 0px;
  top: 0px;
  z-index: 10001;
  padding-left: 50px;
  padding-bottom: 50px;



}

.bv-alert-notification {
  position: relative;
  right: 10px;
  z-index: 1001;
  width: 360px;
  padding: 0.75rem 0.5rem;
  margin-top: 5px;
  background-color: white;
  -webkit-box-shadow: -0.05rem 0.05rem 0.20rem rgba(0, 0, 0, .2) !important;
  box-shadow: -0.05rem 0.05rem 0.20rem rgba(0, 0, 0, .2) !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  -webkit-animation: notification;
  animation: notification;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.vh-xl-100-overflowed {
  overflow-y: auto;
  height: 100vh;
}

.overflow-auto {
  overflow: auto;
}


.yh {
  /* border: 1px solid red; */
  height: 60px;
  width: 30px;
  font-size: 12px;
  font-weight: bold;
  color: #777;
}

.xh {
  width: 50px;

  -webkit-box-flex: 1;

  -ms-flex: 1;

  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.xbar {
  width: 30px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #FFA800;
  -webkit-filter: drop-shadow(20px 30px 1px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(20px 30px 1px rgba(0, 0, 0, 0.05));


}

.ydivider {
  background-color: #EEE;
  height: 1px;
  width: 100%;
  position: absolute;
}

.bv-sumary {
  width: 160px;
  height: 160px;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  border: 13px solid #D9D9D9;
  margin: 0.5rem;
}

.cita_tile {
  width: 100%;
  position: absolute;
  border-radius: 3px;
  height: 60px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.7);
  /* max-width: 150px; */
  margin-top: 5px;
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.cita_tile--single {
  position: relative;
}

.daytile {
  border-radius: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.7);
  /* max-width: 150px; */
  margin: 2px;
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.daytile--title {
  padding: 5px;
  cursor: pointer;
}

.daytile--title:hover {
  background-color: #F5F6F7;
}

.daytile:hover {
  background-color: #EFF6FF;
}

.bar {
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--gray-10);
  margin: 8px 0px;
}

.bar-value {
  top: 0px;
  left: 0px;
  height: 8px;
  border-radius: 4px;
  position: absolute;
  background-color: var(--secondary);
}

#conektaIframeContainer {

  width: auto;
  height: 480px !important;
  width: 500px !important;
  margin-left: auto;
  margin-right: auto;

}

.layout-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.plan-card {
  display: flex;
  flex-direction: column;
  /* background-image: url('/public/assets/images/plan-bg-1.png'); */
  background-size: 130%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 166px;
  width: 100%;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: background-size 0.5s;
}

.plan-card:hover {
  background-size: 120%;

}

.plan-card-square {
  display: flex;
  flex-direction: column;
  /* background-size: 130%; */
  background-size: 130%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-size 0.5s;
}

.plan-card-square:hover {
  background-size: 120%;
}

.plan-card-square-home {
  display: flex;
  flex-direction: column;
  background-size: 125%;
  /* background-size: 300%; */
  background-position: center center;
  background-repeat: no-repeat;
  height: 400px;
  width: 100%;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-size 0.5s;
}

/* .plan-card-square-home:hover {
  background-size:790%;
} */

.plan-card-desc {
  display: flex;
  flex-direction: column;
  /* background-image: url('/public/assets/images/plan-bg-1.png'); */
  background-size: 130%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 260px;
  width: 100%;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: background-size 0.5s;
}

.plan-card-desc:hover {
  background-size: 120%;

}

.user-image {
  height: 50px;
}

.max-h-400 {
  max-height: 400px;
  padding: 10px;
}

.specialist-card {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 12px;

  cursor: pointer;
  user-select: none;
}
.specialist-card--active{
  background-image: linear-gradient(74.6237deg, rgb(44, 173, 110), rgb(138, 189, 36));
}

.specialist-card:hover {
  background-image: linear-gradient(74.6237deg, rgb(44, 173, 110), rgb(138, 189, 36));
}
.specialist-card:hover  .text-gray {
  color: white;
}
.specialist-card:hover  .text-green {
  color: white;
}
.specialist-card--active  .text-gray {
  color: white;
}
.specialist-card--active  .text-green {
  color: white;
}

.tip-card {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0.05rem 0.05rem 0.20rem rgba(0, 0, 0, .15) !important;
  box-shadow: 0.05rem 0.05rem 0.20rem rgba(0, 0, 0, .15) !important;
}

.tip-card--horizontal {
  display: flex !important;
  flex-direction: row !important;
}

.tip-card--horizontal .tip-full-image {

  height: 100px;
  aspect-ratio: 3/2;
}

.tip-card img {
  border-radius: 3px;
}

.tip-card-video {
  max-width: 340px;
}

.circle {
  background-image: linear-gradient(74.6237deg, rgb(44, 173, 110), rgb(138, 189, 36));
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.tip-full {
  height: 300px;
  background-image: url('/public/assets/images/1.jpg');
  background-size: 110%;
  background-position: center -100px;
  background-repeat: no-repeat;
  transition: all 1s;
}

.tip-full:hover {
  background-size: 100%;
}

.tip-full-aux-full-mid-mobile{
  height: 20%;
  background-size: 240%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.04);
  margin-top: -8px;
}

.tip-full-aux {
  /* height: 300px;
  background-size: 125%; */
  height: 350px;
  background-size: 105%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  transition: all 1s;
}

.tip-full-aux:hover {
  background-size: 100%;
}

.tip-full-aux-full-mid {
  height: 100%;
  background-size: 240%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  transition: all 1s;
}

.tip-full-aux-full-mid:hover {
  background-size: 230%;
}

.tip-full-aux-detail-plan {
  height: 225px;
  background-size: 105%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  transition: all 1s;
}

/* .tip-full-aux-detail-plan:hover {
  background-size: 100%;
} */


.tip-full-login {
  /* height: 350px; */
  background-size: 105%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.04);
  transition: all 1s;
}

.tip-full-login:hover {
  background-size: 110%;
}

.tip-full-image {
  height: 150px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.tip-thumb-image {
  height: 80px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-cover{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

}
.bg-cover--end{
  background-position: center 100% !important;
}

@-webkit-keyframes notification {
  from {
    right: -50px;
    opacity: 0;
  }

  to {
    right: 10px;
    opacity: 1;
  }
}

@keyframes notification {
  from {
    right: -50px;
    opacity: 0;
  }

  to {
    right: 10px;
    opacity: 1;
  }
}


.community-section img{
 width: 100% !important;
}
/* home banner */
.home-banner {
  width: 100%;
}

.home-banner-category {
  max-height: 280px;

}

.grap {
  flex-wrap: wrap;
  justify-content: space-around;
}


@media (max-width: 1400px) {
  .bv-modal {
    width: 75%;
    max-height: 40vw;

  }

  .bv-modal--card {
    width: 450px
  }

  .bv-login-form {
    width: 75%;
  }

  .bv-table thead td {
    padding: 0.5rem;
  }

  .bv-table tbody td {
    padding: 0.25rem 0.5rem;
  }
}

@media (max-width: 1200px) {

  .w-25-50-100 {
    width: 50% !important;
  }

  .bv-login-form {
    width: 75%
  }

  .w-50-100 {
    width: 50% !important;
  }

  .vh-xl-100-overflowed {
    overflow-y: unset;
    height: unset;
  }


}

@media (max-width: 992px) {
  .bv-modal {
    width: 100%;
    max-height: 100%;

  }

  .bv-modal--card {
    width: 450px
  }

  .bv-login-form {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  /* .bv-table thead td{
    padding: 0.5rem;
  }
  .bv-table tbody td{
    padding: 0.25rem 0.5rem;
  } */

  /* .bv-card{
    margin-right: 0px;
  } */
  .w-25-50-100 {
    width: 100% !important;
  }



}

@media (max-width: 765px) {
  .mobile-d-none {
    display: none !important;
  }

  .bv-list-option{
    bottom: -200px;
    right: -20px;
  }
  .overflow-auto-mobile {
    overflow: auto;
  }

  /* .mobile-d-block{
    display: block;
  } */
}

@media (max-width: 576px) {


  .bv-quiz .bv-modal {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center 0%;
    height: 90%;
    min-height: 90%;
  }

  .r-contents {

    width: 150%;
    left: calc(0% - 25%);
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    padding-top: 50px;
    padding-bottom: 15px;
    padding-left: 25%;
    padding-right: 25%;
    position: relative;

  }

  .bv_sidemenu {
    width: 40px;

  }

  .bv_sidemenu_item {
    height: 40px;
    /* border: 1px solid red; */
  }

  .bv_sidemenu_item div {
    font-size: 9px !important;
    display: none;
  }

  .bv_sidemenu_item svg {
    width: 18px !important;
  }

  .bv-tab {}

  .f-30 {
    font-size: 26px;
  }

  .f-18 {
    font-size: 14px;
  }

  .home-banner-category {
    max-height: 230px;

  }

  .plan-description .f-16 {
    font-size: 14px;
  }

  .plan-description .f-26 {
    font-size: 22px;
  }

  .desc-container p,
  .desc-container ul {
    font-size: 14px;
  }

  .login_view_form {
    width: 350px;
  }
}

@media (max-width: 447px) {
  .tip-full-aux-detail-plan {
    height: 100px;
    background-size: 151%;
  }

}


@media (min-width: 1400px) {}